import _ from 'lodash';

import { AUTH_LOGIN } from '../config/settings';

export const getUserToken = (): any => {
  const userData: any = {};
  const oidcStorage = JSON.parse(sessionStorage.getItem(`${AUTH_LOGIN}`) || '{}');
  if (!_.isEmpty(oidcStorage)) {
    userData.access_token = oidcStorage.access_token;
    userData.token_type = oidcStorage.token_type;
  }
  return userData;
};
