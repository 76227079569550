import querystring, { ParsedUrlQueryInput } from 'querystring';

import join from 'url-join';

import { API_URL } from '../config/settings';
import { QueryOptions, DataRequest, WithAuthResponse } from '../types';
import { getUserToken } from 'utils/userToken';

import { RequestOptions } from './RequestOptions';

export function buildApiUrl(url: string, path: string, ...args: any): string {
  const extraPaths = args.filter(Boolean).map(String);
  return extraPaths.length > 0 ? join(url, path, ...extraPaths) : join(url, path);
}

export function addIfNotNull(base: any, field: string, value: any): any {
  // eslint-disable-next-line no-param-reassign
  if (value) base[field] = value;
}

export class BaseService {
  path: string;

  constructor(path: string) {
    this.path = path;
  }

  buildQueryOptions = (options: QueryOptions = {}): string => {
    const {
      skip, limit, fields, filters, orders,
    } = options;

    const queryObject: ParsedUrlQueryInput = {};
    addIfNotNull(queryObject, 'skip', skip);
    addIfNotNull(queryObject, 'limit', limit);
    addIfNotNull(queryObject, 'fields', fields);
    addIfNotNull(queryObject, 'filters[]', filters);
    addIfNotNull(queryObject, 'orders[]', orders);

    const query = querystring.encode(queryObject);
    return query ? `${query}` : '';
  }

  withAuth = async (options: DataRequest): Promise<WithAuthResponse> => {
    try {
      const oidcStorage = getUserToken();
      // eslint-disable-next-line max-len
      const headers = Object.assign(options.headers || {}, { Authorization: `${oidcStorage.token_type} ${oidcStorage.access_token}` });
      return { ...options, headers };
    } catch (err) {
      throw new Error(err);
    }
  }

  urlFor = (queryOptions: QueryOptions, base: string, ...args: any): string => {
    const url = buildApiUrl(API_URL, base, ...args);
    const queryString = this.buildQueryOptions(queryOptions);
    return `${url}${queryString}`;
  }

  async options(config: DataRequest): Promise<RequestOptions> {
    return new RequestOptions(await this.withAuth(config));
  }

  async getAll(queryOptions: QueryOptions, ...additionalPathSegments: any): Promise<RequestOptions> {
    // eslint-disable-next-line no-console
    additionalPathSegments.push('?');

    return this.options({ url: this.urlFor(queryOptions, this.path, ...additionalPathSegments) });
  }
}
