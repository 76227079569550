import React, { createContext, useContext, useReducer, useEffect, useRef } from 'react';
import io from 'socket.io-client';

import reducer from './reducer';
import actions from './actions';
import listenEvents from './events';

import initialState from './initialState';

import { getUserToken } from 'utils/userToken';
import { SOCKET_URL } from 'config/settings';
import { emit } from 'utils/emitter';

export const SocketContext = createContext<any | null>(null);

export function useSocketContext() {
  return useContext(SocketContext);
}

export default function SocketContextProvider({ children }: any) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const stateRef = useRef();
  stateRef.current = state;

  useEffect(() => {
    try {
      const socket = io(SOCKET_URL, {
        auth: (cb) => {
          // eslint-disable-next-line camelcase
          const { access_token } = getUserToken();
          cb({ token: access_token });
        },
        reconnectionDelay: 5000,
        reconnectionDelayMax: 30000,
      });
      socket.on('connect', () => {
        const { selectedGroup }: any = stateRef.current;
        if (selectedGroup?._id) {
          socket.emit('joinGroup', selectedGroup._id);
        }
      });
      socket.on('getLocation', (userEvent: any) => {
        emit('socket:userEvent', userEvent);
      });
      socket.on('connectionStatus', (userEvent: any) => {
        const lastUserEvent = userEvent.data;
        emit('socket:globalUserEvent', { lastUserEvent });
      });
      dispatch(actions.INIT_SOCKET(socket));
    } catch (err) {
      dispatch(actions.SET_ERROR(err));
    }
    const unsubscribe = listenEvents(state, dispatch, actions);
    return () => unsubscribe();
  }, []); // eslint-disable-line

  return <SocketContext.Provider value={[state, actions, dispatch]}>{children}</SocketContext.Provider>;
}
