function getEnvVariable(key: string) {
  // For local development, the env variables need to have the prefix `REACT_APP` to be accessible due to CreateReactApp
  return window._env_?.[key] || process?.env?.[`REACT_APP_${key}`] || process?.env?.[key];
}

export const OIDC_SETTINGS = {
  authority : getEnvVariable("SETTINGS_OIDC_AUTHORITY"),
  client_id: getEnvVariable("SETTINGS_OIDC_CLIENT_ID"),
  redirect_uri: getEnvVariable("SETTINGS_OIDC_REDIRECT_URI"),
  silent_redirect_uri: getEnvVariable("SETTINGS_OIDC_SILENT_REDIRECT_URI"),
  post_logout_redirect_uri: getEnvVariable("SETTINGS_OIDC_POST_LOGOUT_REDIRECT_URI"),
  response_type: getEnvVariable("SETTINGS_OIDC_RESPONSE_TYPE"),
  scope : getEnvVariable("SETTINGS_OIDC_SCOPE"),
  automaticSilentRenew: true,
  loadUserInfo: false,
  monitorSession: false,
  response_mode : getEnvVariable("SETTINGS_OIDC_RESPONSE_MODE"),
  client_secret: getEnvVariable("SETTINGS_OIDC_CLIENT_SECRET"),
  includeIdTokenInSilentRenew: true,
  filterProtocolClaims: true,
  metadata: {
    issuer: getEnvVariable("SETTINGS_OIDC_METADATA_ISSUER"),
    jwks_uri: getEnvVariable("SETTINGS_OIDC_METADATA_JWKS_URI"),
    end_session_endpoint: getEnvVariable("SETTINGS_OIDC_METADATA_END_SESSION"),
    authorization_endpoint: getEnvVariable("SETTINGS_OIDC_METADATA_AUTHORIZATION"),
    token_endpoint: getEnvVariable("SETTINGS_OIDC_METADATA_TOKEN")
  }
};

export const AUTH_LOGIN = getEnvVariable("AUTH_LOGIN");
export const API_URL: string = getEnvVariable("BACKEND_API_BASE_URL") || '';
export const SOCKET_URL: string = getEnvVariable("WEB_SOCKET_URL") || '';
