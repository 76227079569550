import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Button, Form } from 'semantic-ui-react';

import MemberSelector from './MemberSelector';
import GroupProjectSelector from './ProjectSelector';
import style from './GroupForm.module.scss';

import { isEmail } from 'utils/validations';
import { useUser } from 'hooks/useUser';
import { useGlobalContext } from 'contexts';
import { buildGroupData, buildGroupToSave } from 'utils/groupHandler';

type Props = {
  group?: any;
  onSave: any;
  errorMessage?: any;
};

export default function GroupForm({ group, onSave, errorMessage }: Props): JSX.Element {
  const [globalState] = useGlobalContext();
  const { user } = globalState;
  const { groupName, groupOwners, groupSharedWith, groupMembers, groupMembersIds, groupProjects } = buildGroupData(
    group,
    user
  );
  const [name, setName] = useState(groupName);
  const [owners, setOwners] = useState<any>(groupOwners);
  const [sharedWith, setSharedWith] = useState(groupSharedWith);
  const [members, setMembers] = useState<any>(groupMembers);
  const [projects, setProjects] = useState<any>(groupProjects);
  const [loading, setLoading] = useState<boolean>(false);
  const { currentUserIsAdmin, currentUserIsManager } = useUser();

  const onChangeName = (event: any, { value }: any) => setName(value);
  const onChangeSharedWith = (newMembers: any) => setSharedWith(newMembers);
  const onChangeOwner = (newMembers: any) => setOwners(newMembers);
  const onMembersChanged = (newMembers: any) => setMembers(newMembers);
  const onProjectsChanged = (newProjects: any) => setProjects(newProjects);

  const handleOnSave = async () => {
    setLoading(true);
    const onlyMembers = _.filter(members, (item: any) => !isEmail(item));
    const originalMembers = groupMembersIds;
    const addMembers = _.difference(onlyMembers, originalMembers);
    const removeMembers = _.difference(originalMembers, onlyMembers);
    const groupToSave = buildGroupToSave(group, user, {
      groupName: name,
      groupSharedWith: sharedWith,
      groupMembers: members,
      groupProjects: projects,
      groupOwners: owners,
    });

    await onSave(groupToSave, addMembers, removeMembers,);
    setLoading(false);
  };

  useEffect(() => {
    if (errorMessage && errorMessage.content) {
      toast.error(`${errorMessage.header}: ${errorMessage.content}`);
    }
  }, [errorMessage]);

  return (
    <section className={style.container}>
      <Form className={style.form}>
        <Form.Input
          className={style.control}
          fluid
          value={name}
          onChange={onChangeName}
          placeholder="Group Name"
          autoComplete="off"
        />
        <header className={style.header}>Owner</header>
        <MemberSelector
          members={owners}
          multiple
          originalMembers={group?.owners ?? [user]}
          onMembersChanged={onChangeOwner}
          allowSelections
        />
        <header className={style.header}>Visibility</header>
        <MemberSelector
          members={sharedWith}
          originalMembers={group?.sharedWithUsers ?? []}
          onMembersChanged={onChangeSharedWith}
          multiple
          allowSelections={currentUserIsAdmin || currentUserIsManager}
        />
        <header className={style.header}>Group Members</header>
        <MemberSelector
          members={members}
          originalMembers={group?.members}
          pendingUsers={group?.pendingUsers}
          onMembersChanged={onMembersChanged}
          multiple
          allowSelections={currentUserIsAdmin || currentUserIsManager}
          allowAdditions={currentUserIsAdmin || currentUserIsManager}
        />
        <header className={style.header}>Group Projects</header>
        <GroupProjectSelector
          projects={projects}
          originalProjects={group?.projects}
          onProjectsChanged={onProjectsChanged}
          allowSelections={currentUserIsAdmin || currentUserIsManager}
          allowAdditions={currentUserIsAdmin || currentUserIsManager}
        />
        <Button as="div" className={style.button} onClick={handleOnSave} disabled={loading}>
          Save
        </Button>
      </Form>
    </section>
  );
}

GroupForm.defaultProps = {
  group: null,
  errorMessage: null,
};
