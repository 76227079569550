import React, { useEffect } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';

import { useGlobalContext } from 'contexts';

import NotFound from 'pages/NotFound';
import CallBack from 'pages/Authentication/CallBack';
import Logout from 'pages/Authentication/LogOut';
import Terms from 'pages/Terms';
import Notification from 'pages/Notification';
import Home from 'layout';

import MobilePage from 'pages/mobile';
import SidebarContextProvider from 'contexts/sidebar';
import AttendanceContextProvider from 'contexts/attendance';
import { useWindowDimensions } from 'hooks/useDimensions';
import SocketContextProvider from 'contexts/socket';
import SilentCallback from 'pages/Authentication/SilentCallback';

// TODO: Remove this mobile pages when all application supports routes reports and timesheet
function HomeMobilePage() {
  return (
    <SocketContextProvider>
      <SidebarContextProvider>
        <AttendanceContextProvider>
          <MobilePage mode="home" />
        </AttendanceContextProvider>
      </SidebarContextProvider>
    </SocketContextProvider>
  );
}

function HomePage(width: number) {
  if (width < 720) {
    return HomeMobilePage;
  }
  return Home;
}

function AttendanceMobilePage() {
  return (
    <SocketContextProvider>
      <SidebarContextProvider>
        <AttendanceContextProvider>
          <MobilePage mode="attendance" />
        </AttendanceContextProvider>
      </SidebarContextProvider>
    </SocketContextProvider>
  );
}

function TimesheetMobilePage() {
  return (
    <SocketContextProvider>
      <SidebarContextProvider>
        <AttendanceContextProvider>
          <MobilePage mode="timesheet" />
        </AttendanceContextProvider>
      </SidebarContextProvider>
    </SocketContextProvider>
  );
}

export default function Routes(): JSX.Element {
  const [, request] = useGlobalContext();
  const { width } = useWindowDimensions();
  const { setCurrentUser } = request;

  useEffect(() => {
    setCurrentUser();
  }, []);

  return (
    <Router>
      <Switch>
        <Route exact path="/auth/oidc" component={CallBack} />
        <Route exact path="/auth/silent-callback" component={SilentCallback} />
        <Route exact path="/logout" component={Logout} />
        <Route path="/terms" component={Terms} />
        <Route exact path="/" render={() => <Redirect to="/home" />} />
        <PrivateRoute exact path="/settings/notification" component={Notification} />
        <PrivateRoute exact path="/home/:location?/:zoom?" component={HomePage(width)} />
        <PrivateRoute exact path="/reports" component={AttendanceMobilePage} />
        <PrivateRoute exact path="/timesheet" component={TimesheetMobilePage} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
}
