import React, { useRef, useState, useEffect } from 'react';
import { Dropdown, Button, Form, Loader } from 'semantic-ui-react';
import dayjs from 'dayjs';

import AutosizeTextArea from '../AutosizeTextarea';

import style from './ActivityForm.module.scss';

import { CATEGORIES } from 'utils/constants';

import { ActivityService } from 'service';

import { toast } from 'react-toastify';

const activityService = new ActivityService();

const defaultErrorContent = {
  header: '',
  content: '',
};

const categoryOptions = Object.entries(CATEGORIES).map(([key, value]) => ({ key, text: value, value: key }));

type Activity = {
  project: {
    id: string,
    groupId?: string,
    name: string,
  },
  category: string,
  comment: string,
  time: number,
  id: string,
};

type ActivityFormProps = {
  userId: string,
  date: string,
  projectOptions: any[],
  autoFocus?: boolean,
  onSave: (andCreateNew: boolean, error?: boolean) => void,
  onCancel: () => void,
  activity: Activity,
  onChangeActivity: (property: string, value: any) => void,
  globalUserId: string,
};

type activityTimeDuration = {
  hours: string,
  minutes: string
};

function activityTimeToString(activity: Activity | undefined): activityTimeDuration {
  const result = { hours: '', minutes: '' };
  if (activity && activity.time && activity.time === 1440) {
    result.hours = '24';
    result.minutes = '00';
  }
  if (activity && activity.time && activity.time !== 1440) {
    const rHours = dayjs.duration(activity.time, 'minutes').hours();
    const rMinutes = dayjs.duration(activity.time, 'minutes').minutes();
    result.hours = rHours < 10 ? `0${rHours}` : rHours.toString();
    result.minutes = rMinutes < 10 ? `0${rMinutes}` : rMinutes.toString();
  }
  return result;
}

function activityTimeToNumber({ hours, minutes }: activityTimeDuration): number {
  return dayjs.duration(Number(hours), 'hours').asMinutes() + Number(minutes);
}

function getActivityDate(value: string): string {
  return `${value}T00:00:00.000Z`;
}

export default function ActivityForm({
  userId,
  date,
  projectOptions,
  onSave,
  onCancel,
  activity,
  onChangeActivity,
  autoFocus,
  globalUserId,
}: ActivityFormProps): JSX.Element {
  const [inProgress, setInProgress] = useState(false);
  const [initialState] = useState(activity);
  const [duration, setDuration] = useState<activityTimeDuration>(activityTimeToString(activity));
  const [errorMessage, setErrorMessage] = useState(defaultErrorContent);
  const formContainerRef = useRef<HTMLElement>(null);
  const categoryClassName = 'categoryField';

  useEffect(() => {
    if (autoFocus && formContainerRef && formContainerRef.current) {
      const categoryInput = formContainerRef.current.querySelector(`.${categoryClassName}`)?.querySelector('input');
      categoryInput?.focus();
    }
  }, [autoFocus]);

  useEffect(() => {
    if ((+duration.hours === 24 && +duration.minutes > 0) || +duration.hours >= 25 || +duration.minutes >= 60) {
      setDuration({ hours: '', minutes: '' })
      toast.error('Please enter a valid time');
    } else {
      onChangeActivity('time', activityTimeToNumber(duration));
    }
  }, [duration]);

  useEffect(() => {
    if (errorMessage && errorMessage.content) {
      toast.error(`${errorMessage.header}: ${errorMessage.content}`);
    }
  }, [errorMessage]);

  function errorHandling(error: any): void {
    if (error.response && error.response.data && error.response.data.error) {
      const { message, details } = error.response.data.error;
      if (details.length) {
        setErrorMessage({ header: message, content: details.map((detail: any) => detail.message).join('\n') });
      } else {
        setErrorMessage({ header: 'Server Error', content: message });
      }
    } else {
      setErrorMessage(error.message);
    }
  }

  async function saveActivity(): Promise<void> {
    let activityRequest = null;
    const activityData = {
      category: activity.category,
      time: activity.time,
      comment: activity.comment,
      date: getActivityDate(date),
      project: activity.project,
    };
    if (activity.id) {
      activityRequest = await activityService.updateAll(activity.id, activityData, userId, globalUserId);
    } else {
      activityRequest = await activityService.create(userId, activityData, globalUserId);
    }
    if (!activityRequest) return;
    await activityRequest.toAxios();
  }

  async function onSaveActivity(): Promise<void> {
    setInProgress(true);
    let errorToSave = false;
    try {
      await saveActivity();
    } catch (error) {
      errorHandling(error);
      errorToSave = true;
    } finally {
      setInProgress(false);
    }
    onSave(false, errorToSave);
  }

  async function onSaveAndCreateActivity(): Promise<void> {
    setInProgress(true);
    try {
      await saveActivity();
      setErrorMessage(defaultErrorContent);
    } catch (error) {
      errorHandling(error);
    } finally {
      setDuration({
        hours: '',
        minutes: '',
      });
      setInProgress(false);
    }
    onSave(true);
  }

  function onChangeDuration({ currentTarget: { value, name } }: React.ChangeEvent<HTMLInputElement>) {
    if (value && !/^\d{1,2}$/.test(value)) return;
    setDuration({ ...duration, [name]: value });
  }

  function onChangeCategory(e: any, data: any) {
    onChangeActivity('category', data.value);
  }

  function onChangeComment(e: any, data: any) {
    onChangeActivity('comment', data.value);
  }

  function onChangeProject(e: any, data: any) {
    onChangeActivity('project', data.value);
  }

  function getButtonState() {
    let isDisable = true;
    if (activity.id) {
      const isSameCategory = activity.category === initialState.category;
      const isSameProject = activity.project.id === initialState.project.id;
      const isSameTime = activity.time === initialState.time;
      const isSameComment = activity.comment === initialState.comment;
      isDisable = isSameCategory && isSameTime && isSameComment && isSameProject;
    } else {
      const requiredFieldAreEmpty = !(activity.category && activity.time && activity.project.id);
      isDisable = requiredFieldAreEmpty;
    }
    return isDisable;
  }

  return (
    <section className={style.container} ref={formContainerRef}>
      <Form className={style.form}>
        <span className={style.title}>Project</span>
        <Form.Field
          control={Dropdown}
          onChange={onChangeProject}
          options={projectOptions}
          placeholder="Project"
          selection
          className={style.project}
          fluid
          search
          selectOnBlur={false}
          value={activity?.project?.id ?? ''}
        />
        <span className={style.title}>Category</span>
        <Form.Group className={style.categoryTime}>
          <Form.Field
            className={style.category}
            control={Dropdown}
            onChange={onChangeCategory}
            options={categoryOptions}
            placeholder="Category"
            openOnFocus={false}
            search
            fluid
            selection
            selectOnBlur={false}
            value={activity.category}
            autoFocus
          />
          <Form.Field className={style.durationHours}>
            <Form.Input
              name="hours"
              placeholder="HH"
              autoComplete="off"
              value={duration.hours}
              onChange={onChangeDuration}
            />
          </Form.Field>
          <span className={style.durationSeparator}>:</span>
          <Form.Field className={style.durationMinutes}>
            <Form.Input
              name="minutes"
              placeholder="MM"
              autoComplete="off"
              value={duration.minutes}
              onChange={onChangeDuration}
            />
          </Form.Field>
        </Form.Group>
        <Form.Field
          control={AutosizeTextArea}
          onChange={onChangeComment}
          value={activity.comment}
          placeholder="Write a comment"
          className={style.commentClassName}
          rows={3}
        />
        {inProgress ? (
          <Loader size="small" active inline="centered" />
        ) : (
          <>
            <Button className={style.button} onClick={onSaveActivity} disabled={getButtonState()}>
              {activity.id ? 'Update' : 'Save'}
            </Button>
            {!activity.id && (
              <Button className={style.button} onClick={onSaveAndCreateActivity} disabled={getButtonState()}>
                Save & New
              </Button>
            )}
            <Button secondary onClick={onCancel}>
              Cancel
            </Button>
          </>
        )}
      </Form>
    </section>
  );
}

ActivityForm.defaultProps = {
  autoFocus: false,
};
